<template>
  <div class="application">

    <horizontal-table :panes="navmenus" :activeName="$route.name" @click="handleClick"></horizontal-table>
    <router-view class="application-container"></router-view>
  </div>
</template>

<script>
  import HorizontalTable from '@/components/HorizontalTable'

  export default {
    provide () {
      return {
        icons: this.icons
      }
    },
    data() {
      return {
        icons: [
          { icon: 'iconfont icondingdan' },
          { icon: 'iconfont icon4_chakan' },
          { icon: 'iconfont icon10_fasong' },
          { icon: 'iconfont icon28_fenpeiyonghu' },
          { icon: 'iconfont icon19_taocan' },
          { icon: 'iconfont icon46_shouye' },
          { icon: 'iconfont icon50_xitongxinxi' },
          { icon: 'iconfont icon47_jixiaoqunzu' },
          { icon: 'iconfont icon51_gongzuoqunzu' },
          { icon: 'iconfont icon49_quanxianshezhi' },
          { icon: 'iconfont icon41_shezhi' },
          { icon: 'iconfont iconzuzhiqunzu' },
          { icon: 'iconfont icon65_zhifushezhi' },
          { icon: 'iconfont icon72_yingyong' },
          { icon: 'iconfont icon40_erweima' },
          { icon: 'iconfont icon58_kaquan' },
          { icon: 'iconfont icon57_zhishiku' },
          { icon: 'iconfont iconfenxipingbi' },

        ],
        navmenus: [
          {
            label: '应用分类',
            name: 'system.application.moduleSort'
          },
          {
            label: '应用定义',
            name: 'system.application.module'
          }
        ]
      }
    },
    components: {
      HorizontalTable
    },
    methods: {
      handleClick(name) {
        this.$router.replace({name: name})
      }
    }

  }
</script>

<style lang="scss">
  .application {
    &-container{
      padding: 0 $padding-8;
    }
    min-height: 608px;
    border-radius: $border-radius;
    @media (max-height: 768px) {
      min-height: 576px;
    }
  }
  .icon-check{

    .icon-list{
      padding: 16px;
      border-bottom: 1px solid #e2e2e2;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-bottom: $padding;
    }
  }

</style>
