<template>
  <div class="horizontal-table">
    <el-tabs v-model="localActiveName">
      <el-tab-pane :label="pane.label" :name="pane.name" v-for="pane in panes"></el-tab-pane>
    </el-tabs>
  </div>

</template>

<script>
  export default {
    name: '',
    props: {
      activeName: {
        type: String,
        default: '',
      },
      panes: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    components: {},
    mounted: function () {
    },
    data: function () {
      return {
        localActiveName: this.activeName,
      }
    },
    watch: {
      localActiveName(val) {
        this.$emit('update:activeName', val);
        this.$emit('click', val);
      }
    },
    methods: {},
  }
</script>

<style lang="scss">

  .horizontal-table {
    height: 48px;

    .el-tabs__item {
      @include set_font_color($--font-color, $--font-color-dark);
      height: 40px;
      line-height: 48px;
      font-weight: normal;
    }

    .el-tabs__nav-wrap::after {
      @include set_bg_color($background-color-light-2, $background-color-dark-1);
    }
  }

</style>
